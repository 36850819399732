import { AppUtilsProvider } from "providers/AppUtilsProvider";
import { FiltersProvider } from "providers/FiltersProvider";
import "react-loading-skeleton/dist/skeleton.css";
import ErrorBoundary from "../middleware/errorBoundary";
import "../styles/input.css";

import AppContent, { IAppContent } from "@modules/app/AppContent";

import { HeaderProvider } from "@contexts/HeaderContext";
import { Roboto } from "next/font/google";

const roboto = Roboto({
  weight: ["400", "500", "700"],
  style: "normal",
  subsets: ["latin"],
  display: "swap",
  variable: "--font-roboto",
});

export default function MyApp({
  Component,
  pageProps,
}: IAppContent): JSX.Element {
  return (
    <div className={`${roboto.variable} font-sans`}>
      <ErrorBoundary>
        <FiltersProvider pageProps={pageProps}>
          <AppUtilsProvider>
            <HeaderProvider>
              <AppContent Component={Component} pageProps={pageProps} />
            </HeaderProvider>
          </AppUtilsProvider>
        </FiltersProvider>
      </ErrorBoundary>
    </div>
  );
}
